import React from "react";
import "./index.scss";
import { Grid, Button } from "@mui/material";
import DetailHeader from "../../DetailHeader";
import EditButton from "../../Inputs/EditButton";
import { useWindowSize } from "../../../../utils/customHooks/useWindowSize";
import { ErrorBox } from "../../Helpers/ErrorBox";
import PropTypes from "prop-types";

export default function TabGridAndFormLayout({
  canView = true,
  showGrid,
  children,
  title,
  beingEdited,
  onEdit,
  backLabel,
  backAction,
  hideEdit,
  onSave,
  onCancel
}) {
  const { isMobile } = useWindowSize();
  if (showGrid) {
    return <div> {canView ? children : <ErrorBox message="You don't have permission to view this page." />}</div>;
  }
  return (
    <div className="proForm-layout proTabGridForm">
      <DetailHeader
        title={title}
        editButton={<EditButton onClick={onEdit} isMobile={isMobile} beingEdited={beingEdited} hideEdit={hideEdit} />}
        hideBackbutton={!backAction}
        backAction={backAction}
        backLabel={backLabel}
        fromGridAndForm={true}
      />
      <div className="proForm-layout__content__wrapper">
        <div className="proTabGridForm-content">
          <Grid container spacing={2}>
            {children}
          </Grid>
        </div>
      </div>
      <div className="proForm-layout__footer">
        <div className="proForm-layout__footer__actions">
          {beingEdited && (
            <Button className="cancel-btn" variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
          {beingEdited && (
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}


TabGridAndFormLayout.propTypes = {
  canView: PropTypes.bool,
  showGrid: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  beingEdited: PropTypes.bool,
  onEdit: PropTypes.func,
  backLabel: PropTypes.string,
  backAction: PropTypes.func,
  hideEdit: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};