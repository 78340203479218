import "./App.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import AuthWrapper from "./App/Components/AuthWrapper";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from "./App/RouterComponent/RouterComponent";
import { Suspense, useEffect, useState } from "react";
import Loading from "./App/Components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { loginSelector } from "./App/store/features/auth/authSlice";
import { addWatchForBuildVesion } from "./utils/watchForBuild";

const App = () => {
  const [filteredRoutes, setFilteredRoutes] = useState(createBrowserRouter(router));
  const { user } = useSelector(loginSelector);

  useEffect(() => {
    if (user) {
      setFilteredRoutes(createBrowserRouter(router.filter(({ role }) => !role || role === (user.projectEmployeeIsMentor ? "mentor" : "employee"))));
    } else setFilteredRoutes(createBrowserRouter(router));
  }, [user]);

  useEffect(() => {
    addWatchForBuildVesion();
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      <AuthWrapper>
        <RouterProvider router={filteredRoutes} />
      </AuthWrapper>
      <ToastContainer theme="colored" />
    </Suspense>
  );
};

export default App;
