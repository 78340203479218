import React from "react";
import ReadOnlyInput from "./ReadOnlyInput";
import InputWrapper from "./InputWrapper";
import { InputAdornment, TextField } from "@mui/material";
import MobileIcon from "../../Assets/IconComponents/MobileIcon";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import ReactInputMask from "react-input-mask";
import { useTheme } from "../../ContextStore/ThemeContext";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
export default function MobileField({
  name,
  mode,
  label,
  rules,
  control,
  isAddMode,
  fieldProps = {},
  size = DEFAULT_SIZE,
  startAdornment = null,
  ...rest
}) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput name={name} label={t(label)} size={size} type="mobile" {...rest} />;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={{
          ...(rules || {}),
          validate: {
            mobile: value => {
              if (!value) return;
              const trimmerdValue = value.replace(/[/D]/g, "");
              if (trimmerdValue.length < 10) return "Invalid mobile number";
            }
          }
        }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <ReactInputMask mask="(999) 999-9999" maskChar="" value={value} onBlur={onBlur} onChange={onChange}>
            {() => (
              <TextField
                label={t(label)}
                variant="outlined"
                fullWidth
                placeholder={t(label)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MobileIcon fill={theme === "dark" ? "#ffffff" : "#00050B"} />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{ shrink: true }}
                helperText={error?.message || null}
                error={!!error}
                {...fieldProps}
              />
            )}
          </ReactInputMask>
        )}
      />
    </InputWrapper>
  );
}

MobileField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['read', 'edit']).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueclick:PropTypes.func,
  size: PropTypes.string,
  startAdornment: PropTypes.node,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
};
