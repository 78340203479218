import { Box, Divider, IconButton, List, ListItem, Popover, Tooltip, Typography, CardMedia } from "@mui/material";
import { useRef, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_ALL_VIDEOS } from "../../../utils/services/apiPath";
import { getApi } from "../../../utils/services";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { loginSelector } from "../../store/features/auth/authSlice";
import { errorToast } from "../../../utils/toastHelper";
import { useSelector } from "react-redux";

function HeaderVideos() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user } = useSelector(loginSelector);
  const clientUserId = user?.id;

  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState([]);

  const handleOpen = () => {
    fetchAllVideos();
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (!clientUserId) fetchAllVideos();
  }, []);

  const fetchAllVideos = useCallback(async () => {
    const { error, data } = await getApi(GET_ALL_VIDEOS, {});
    if (error) return errorToast(error);
    setVideos(data || []);
    setIsOpen(true);
  }, []);

  return (
    <>
      <Tooltip arrow title={t("Videos")}>
        <IconButton onClick={handleOpen}>
          <OndemandVideoIcon />
        </IconButton>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box
          sx={{
            p: 2,
            width: 450
          }}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t("Videos")}</Typography>
        </Box>
        <Divider />
        <List
          sx={{
            p: 2
          }}
        >
          {videos?.length === 0 ? (
            <Typography component="span" variant="body2">
              No video here
            </Typography>
          ) : (
            videos?.map(item => {
              return (
                <>
                  <ListItem
                    sx={{
                      display: { xs: "block", sm: "flex" }
                    }}
                  >
                    <Box flex={1} sx={{ maxWidth: 450 }}>
                      <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between">
                        <Typography component="span" variant="body2">
                          {item.title}
                        </Typography>
                      </Box>
                      <CardMedia sx={{ mt: 1 }} component="iframe" src={item.videoUrl} />
                    </Box>
                  </ListItem>
                  <Divider />
                </>
              );
            })
          )}
        </List>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderVideos;
