import { Avatar, Box, Card, Grid, Stack, Typography, Tooltip, Paper, Dialog } from "@mui/material";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/x-charts/BarChart";
import { useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../../utils/services";
import { GET_APPRENTICE_DASHBOARD, GET_ALL_DOCUMENTS, UPDATE_APPRENTICE_SIGNATURE_STATUS } from "../../../utils/services/apiPath";
import { STATUS_MAPPINGS, STAUTES } from "../../../utils/constant";
import Layout from "../../Components/Layout";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import { errorToast } from "../../../utils/toastHelper";
import RegistrationPendingModel from "../../Components/RegistrationPendingModel";
import SignDocumentModel from "../../Components/SignDocumentModel";
import { renderFormattedDate } from "../../../utils/formHelper";
import { useWindowSize } from "../../../utils/customHooks/useWindowSize";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector(loginSelector);
  const [approvedJobTitle, setApprovedJobTitle] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [mentorName, setMentorName] = useState("");
  const [mentorDate, setMentorDate] = useState("");
  const [otjHours, setOtjHours] = useState(0);
  const [rtiHours, setRtiHours] = useState(0);
  const [wageEarned, setWageEarned] = useState(0);
  const [wpsActivityHours, setWPSActivityHours] = useState([]);
  const [rtiActivityHours, setRTIActivityHours] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [rapCategory, setRAPCategory] = useState("");
  const [wPSPendingAdJHours, setWPSPendingAdJHours] = useState(0);
  const [wPSPendingHours, setWPSPendingHours] = useState(0);
  const [is61Form, setIs61Form] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [formLink, setFormLink] = useState();
  const [pendingItems, setPendingItems] = useState([]);
  const { width: _width } = useWindowSize();

  const chartWrapper = useRef();

  const clientUserProfilePicture = user.clientUserProfilePicture;

  const handleOpen = type => {
    setOpen(true);
    if (type === "Registration") setIs61Form(false);
    else setIs61Form(true);
  };

  const onClose = () => {
    setDocumentOpen(false);
  };

  const handleSignDocument = () => {
    setDocumentOpen(true);
  };

  const getAllDocuments = useCallback(async employeeId => {
    const payload = {
      pageIndex: 1,
      pageSize: 10,
      orderByAscending: false,
      searchString: ""
    };
    const { data, error } = await postApi(GET_ALL_DOCUMENTS, payload, { params: { employeeId: employeeId } });
    if (error) return errorToast(error);
    setFormLink(data?.[0]?.firstSignerSharedLink);
  }, []);

  const fetchApprenticeDashboardDetail = useCallback(async employeeId => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await getApi(`${GET_APPRENTICE_DASHBOARD}/${employeeId}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setApprovedJobTitle(data.approvedJobTitle);
    setEmployerName(data.employer);
    setMentorName(data.employeeMentor);
    setMentorDate(data.employeeMentorDate);
    setOtjHours(data.otjHours);
    setRtiHours(data.rtiHours);
    setWageEarned(data.wageEarned?.toLocaleString("en-US", { style: "currency", currency: "USD" }));
    setWPSActivityHours(data.wpsActivityHours);
    setRTIActivityHours(data.rtiActivityHours);
    setProjectName(data.projectName);
    setProjectLocation(data.projectLocation);
    setRAPCategory(data.rapCategory);
    setWPSPendingAdJHours(data.wpsPendingAdJHours);
    setWPSPendingHours(data.wpsPendingHours);
    localStorage.setItem("registrationStatus", data.apprenticeStatus.isFormCompleted);
    
    
    const tempPendingItems = Object.entries(STATUS_MAPPINGS)
      .filter(([key]) => data?.documentStatus?.includes(key))
      .map(([, value]) => t(value));
    
    setPendingItems(tempPendingItems);
    if (!data.apprenticeStatus.isFormCompleted) handleOpen("Registration");
    else if (!data.apprenticeStatus.isESignedCompleted) handleSignDocument();
  }, []);

  useEffect(() => {
    if (user) {
      getAllDocuments(user.employeeId);
      fetchApprenticeDashboardDetail(user.employeeId);
    }
  }, [user, t]);

  useEffect(() => {
    window.addEventListener("message", async event => {
      if (isValidJSON(event.data)) {
        const parsedObject = JSON.parse(event.data);
        const signedStatus = parsedObject.data.document.recipients[0].signer_state;
        const docId = parsedObject.data.document.id;
        const obj = {
          documentId: docId,
          SignedStatus: signedStatus,
          Email: parsedObject.data.document.recipients[1].email,
          FirstName: parsedObject.data.document.recipients[1].first_name,
          LastName: parsedObject.data.document.recipients[1].last_name,
          ApprenticeName: parsedObject.data.document.recipients[0].first_name + " " + parsedObject.data.document.recipients[0].last_name,
          FormName: parsedObject.data.document.name
        };
        await postApi(UPDATE_APPRENTICE_SIGNATURE_STATUS, obj);
      } else {
        console.log("Not valid JSON");
      }
    });
  }, []);

  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <Layout status={status}>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <Card sx={{ px: 2, py: 2, borderRadius: "10px" }}>
              <Stack direction="row" justifyContent="center" p={2}>
                <Avatar sx={{ width: 90, height: 90 }} src={clientUserProfilePicture} />
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Typography align="center" variant="h6" fontWeight="700" fontSize={16}>
                  {user.firstName} {user.lastName}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Typography align="left" variant="h6" fontWeight="600" fontSize={12}>
                  {t("CurrentProject")}
                </Typography>
                <Typography align="right" variant="h6" fontWeight="600" fontSize={12} color="text.secondary">
                  {projectName}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography align="left" variant="h6" fontWeight="600" fontSize={12}>
                  {t("Location")}
                </Typography>
                <Typography align="right" variant="h6" fontWeight="600" fontSize={12} color="text.secondary">
                  {projectLocation}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography align="left" variant="h6" fontWeight="600" fontSize={12}>
                  {t("RAPCategory")}
                </Typography>
                <Typography align="right" variant="h6" fontWeight="600" fontSize={12} color="text.secondary">
                  {rapCategory}
                </Typography>
              </Stack>
            </Card>
            <Tooltip title={t("WPSPendingApprovalTooltip")}>
              <Card sx={{ px: 2, py: 2, mt: 2, borderRadius: "10px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography align="left" variant="h6" fontWeight="600">
                    {t("WPSPendingApproval")}
                  </Typography>
                  <Typography fontSize={12} color="text.secondary" align="right">
                    {wPSPendingHours}
                    {t(" Hours")}
                  </Typography>
                </Stack>
              </Card>
            </Tooltip>
            <Tooltip title={t("OTJTooltipText")}>
              <Card sx={{ px: 2, py: 2, mt: 2, borderRadius: "10px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography variant="h6" fontWeight="600">
                    {t("OTJHours")}
                  </Typography>
                  <Typography fontSize={12} color="text.secondary">
                    {`${Math.floor(otjHours)} Hours ${((otjHours * 60) % 60).toFixed(0)} Minutes`}
                  </Typography>
                </Stack>
              </Card>
            </Tooltip>
            <Tooltip title={t("RTITooltipText")}>
              <Card sx={{ px: 2, py: 2, mt: 2, borderRadius: "10px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography variant="h6" fontWeight="600">
                    {t("RTIHours")}
                  </Typography>
                  <Typography fontSize={12} color="text.secondary">
                    {`${Math.floor(rtiHours / 60)} Hours ${(rtiHours % 60).toFixed(0)} Minutes`}
                  </Typography>
                </Stack>
              </Card>
            </Tooltip>
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <div style={{ overflow: "auto", height: "calc(100vh - 150px)", paddingBottom: "3px" }}>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm={12} xs={12}>
                      <Card sx={{ px: 2, py: 2, borderRadius: "10px" }}>
                        <Typography variant="h6" fontWeight="600">
                          {approvedJobTitle}
                        </Typography>
                        <Typography fontSize={12} color="text.secondary">
                          {t("RegisteredApprenticeshipProgram")}
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <Card sx={{ px: 2, py: 2, borderRadius: "10px" }}>
                        <Typography variant="h6" fontWeight="600">
                          {employerName}
                        </Typography>
                        <Typography fontSize={12} color="text.secondary">
                          {t("Employer")}
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <Card sx={{ px: 2, py: 2, borderRadius: "10px" }}>
                        <Typography variant="h6" fontWeight="600">
                          {mentorName} {mentorDate ? `(${renderFormattedDate(mentorDate)})` : ""}
                        </Typography>
                        <Typography fontSize={12} color="text.secondary">
                          {t("Mentor")}
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Card sx={{ px: 2, py: 2, borderRadius: "10px", overflow: "auto" }}>
                    <Stack direction="row" justifyContent="center" p={0.5}>
                      <Typography align="center" variant="h6" fontWeight="600" fontSize={14}>
                        {t("WPSTracker")}
                      </Typography>
                    </Stack>
                    <div ref={chartWrapper}>
                      <BarChart
                        dataset={!wpsActivityHours?.length ? [{ wpsTitle: "", approvedHours: 0, requiredHours: 0 }] : wpsActivityHours}
                        yAxis={[{ scaleType: "band", dataKey: "wpsTitle" }]}
                        series={[
                          { dataKey: "approvedHours", label: t("ApprovedHours") },
                          { dataKey: "requiredHours", label: t("RequiredHours") }
                        ]}
                        width={chartWrapper.current?.offsetWidth || 500}
                        margin={{ left: 300 }}
                        height={300}
                        layout={"horizontal"}
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Card sx={{ px: 2, py: 2, borderRadius: "10px", overflow: "auto" }}>
                    <Stack direction="row" justifyContent="center" p={0.5}>
                      <Typography align="center" variant="h6" fontWeight="600" fontSize={14}>
                        {t("RTITracker")}
                      </Typography>
                    </Stack>

                    <BarChart
                      dataset={!rtiActivityHours?.length ? [{ rtiTitle: "", creditHours: 0, progressCreditHours: 0 }] : rtiActivityHours}
                      yAxis={[{ scaleType: "band", dataKey: "rtiTitle" }]}
                      series={[
                        { dataKey: "creditHours", label: t("Credit Hours") },
                        { dataKey: "progressCreditHours", label: t("Progress Credit Hours") }
                      ]}
                      width={chartWrapper.current?.offsetWidth || 500}
                      height={(rtiActivityHours?.length * 40 || 40) + 100}
                      margin={{ left: 300 }}
                      layout={"horizontal"}
                    />
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      <RegistrationPendingModel
        open={open}
        title={is61Form ? t("ApprenticeRegistrationTitle") : t("ApprenticeRegistrationPendingTitle")}
        message={is61Form ? t("ApprenticeRegistrationText") : t("ApprenticeRegistrationPendingText")}
        pendingItems={[]}
        onConfirm={() => navigate(is61Form ? "/documents" : "/registration")}
        confirmBtnText={t("Ok")}
      />
      <SignDocumentModel open={documentOpen} title={t("SignDocument")} formLink={formLink} handleClose={onClose} />
    </Layout>
  );
};

export default Dashboard;
