import { getApi } from "./services";
import { GET_BUILD_VERSION } from "./services/apiPath";

let watcher;

const matchVersion = async () => {
  const version = localStorage.getItem("clientBuildVersion");
  const currentVerion = await getApi(GET_BUILD_VERSION, { params: { portalType: 3 } });
  if (!version) {
    localStorage.setItem("clientBuildVersion", currentVerion);
  } else if (version !== currentVerion) {
    localStorage.setItem("clientBuildVersion", currentVerion);
    window.location.reload();
  }
};

export const addWatchForBuildVesion = () => {
  clearInterval(watcher);
  watcher = setInterval(matchVersion, 100000);
  matchVersion();
};
