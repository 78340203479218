import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const MyCourses = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getRedirectUrl = id => {
    switch (id) {
      case "osha-30-hour-construction":
        return "https://portal.clicksafety.com/";
      case "introduction-to-solar-photovoltaics":
        return "https://elearn.proscore.ai/course/introduction-to-solar-photovoltaics/";
      default:
        return null;
    }
  };

  useEffect(() => {
    const url = getRedirectUrl(id);
    if (url) window.open(url, "_blank");
    navigate("/dashboard");
  }, [id]);
};

export default MyCourses;
