const esJSON = {
  Portal: 'Portal',
  General: 'General',
  Dashboard: 'Tablero',
  LanguageSwitcher: 'Selector de Idioma',
  Notifications: 'Notificaciones',
  MyAccount: 'Mi Cuenta',
  ThemeSettings: 'Configuración del Tema',
  GeneralSettings: 'Configuración General',
  DarkColorSchemes: 'Esquemas de Colores Oscuros',
  LightColorSchemes: 'Esquemas de Colores Claros',
  Signout: 'Cerrar Sesión',
  Projects: 'Proyectos',
  Registration: 'Registro',
  SubHeading: 'Complete el formulario a continuación',
  ApprenticePersona: 'Persona de Aprendiz',
  CurrentProject: 'Proyecto Actual',
  Location: 'Ubicación',
  RAPCategory: 'Categoría RAP',
  RegisteredApprenticeshipProgram: 'Programa de Aprendizaje Registrado',
  Mentor: 'Mentor',
  Employer: 'Empleador',
  WageEarned: 'Salario Ganado hasta la Fecha',
  PendingApproval: 'Aprobación Pendiente',
  WPSAdjPending: 'Ajuste WPS Pendiente',
  WPSSubmission: 'Envío de WPS',
  OTJHours: 'Horas OTJ / WPS Trabajadas',
  RTIHours: 'Horas RTI Completadas',
  OTJTooltipText: 'Horas de Trabajo en el Empleo / Horario del Proceso de Trabajo',
  RTITooltipText: 'Instrucciones Técnicas Relacionadas',
  WPSTracker: 'Seguimiento del Horario del Proceso de Trabajo',
  RTITracker: 'Seguimiento de Instrucciones Técnicas Relacionadas',
  InProgress: 'En Progreso',
  Completed: 'Completado',
  Company: 'Empresa',
  Project: 'Proyecto',
  Journeyman: 'Oficial',
  OnlineTraining: 'Formación en Línea',
  BasicInformation: 'Información Básica',
  Payout: 'Pago',
  FirstName: 'Primer Nombre',
  MiddleName: 'Segundo Nombre',
  LastName: 'Apellido',
  CellPhone: 'Teléfono Celular',
  Email: 'Correo Electrónico',
  SSN: 'SSN',
  DateofBirth: 'Fecha de Nacimiento',
  Sex: 'Género',
  ApprenticeshipNumber: 'Número de Aprendizaje',
  ApprenticeInformation: 'Información del Aprendiz',
  Ethnicity: 'Etnicidad',
  VeteranStatus: 'Estado de Veterano',
  Race: 'Raza',
  EducationalLevel: 'Nivel Educativo',
  Disability: 'Discapacidad',
  EmploymentStatus: 'Estado de Empleo',
  DateApprenticeshipBegins: 'Fecha de Inicio del Aprendizaje',
  CareerConnection: 'Conexión de Carrera',
  ApprenticeshipsProgram: 'Programa de Aprendizaje',
  LearningExperience: '¿Alguna acreditación por Experiencia de Aprendizaje Previo en el Trabajo? (La duración total del término es de 4000 horas)',
  InstructionExperience: 'RIL o Instrucción Relacionada, ¿algún crédito por Experiencia de Instrucción Previo?',
  AddressInformation: 'Información de Dirección',
  Address: 'Dirección',
  Address2: 'Dirección 2',
  State: 'Estado',
  City: 'Ciudad',
  Zip: 'Código Postal',
  Submit: 'Enviar',
  Cancel: 'Cancelar',
  WPSTimeAllocation: 'Asignación de Tiempo de WPS',
  MyCourses: 'Mis Cursos',
  ApprovedHours: 'Horas Aprobadas',
  RequiredHours: 'Horas Requeridas',
  TotalApprovedHours: 'Total de Horas Aprobadas : ',
  ApprovedBy: 'Aprobado Por -',
  WPSPendingApproval: 'Aprobación Pendiente de WPS',
  Documents:'Documentos',
  FormName:'Nombre del formulario',
  CreatedDate:'Fecha de creación',
  ESignedStatus:'Estado de firma electrónica',
  RAPIDStatus:'Estado RÁPIDO',
  Actions:'Comportamiento',
  SignDocument:'Firmar documento',
  ProfilePicture:'Foto de perfil',
  Upload:'Subir',
  CreditHours:'Horas de crédito',
  CreditHoursProgress:'Progreso de horas de crédito',
  IsApproved:'Esta aprobado',
  WeekEnding: 'Semana que Termina',
  WPSPendingApprovalTooltip:'Cronograma del proceso de trabajo pendiente de aprobación',
  WPSPendingAdjustmentTooltip:'Ajuste del cronograma del proceso de trabajo pendiente',
  AccessUserManualTootip:'Haga clic aquí para acceder al manual de usuario',
  ApprenticeRegistrationPendingTitle:'Registro de aprendiz pendiente',
  ApprenticeRegistrationTitle:'Registro de aprendiz',
  ApprenticeRegistrationPendingText:'No ha completado algunos datos requeridos para procesar su Registro de Aprendiz con el Departamento de Trabajo. Por favor, complete los siguientes campos faltantes:',
  ApprenticeRegistrationRAPPendingText:'Estamos trabajando para procesar su registro de aprendiz. Una vez procesado, podrá comenzar su viaje como aprendiz.',
  ApprenticeRegistrationText:'Su Formulario de registro de aprendiz (Formulario 671) ya está disponible para su firma; haga clic en Aceptar para ver y firmar el Formulario de registro.',
  EditDetails: 'Editar detalles',
  Save: 'Guardar',
  MyMentors: 'Mis Mentores',
  MentorName: 'Nombre del Mentor',
  ApprenticeName: 'Nombre del Aprendiz',
  ProjectName: 'Nombre del Proyecto',
  WorkDate: 'Fecha de Trabajo',
  employeeName: 'Nombre del Empleado',
  estimatedHours: 'Horas estimadas',
  Videos: "Videos"
};

export default esJSON;
