import React from "react";
import PropTypes from "prop-types";

const NotificationIcon = ({ color = "none", isUnReadNotifications = false }) => {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 11C18 9.4087 17.3679 7.88258 16.2426 6.75736C15.1174 5.63214 13.5913 5 12 5C10.4087 5 8.88258 5.63214 7.75736 6.75736C6.63214 7.88258 6 9.4087 6 11C6 18 3 20 3 20H21C21 20 18 18 18 11Z"
        stroke="#5D6976"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7295 24C13.5537 24.3031 13.3014 24.5547 12.9978 24.7295C12.6941 24.9044 12.3499 24.9965 11.9995 24.9965C11.6492 24.9965 11.3049 24.9044 11.0013 24.7295C10.6977 24.5547 10.4453 24.3031 10.2695 24"
        stroke="#5D6976"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {isUnReadNotifications && <circle cx="19" cy="3" r="3" fill="#E97205" />}
    </svg>
  );
};

NotificationIcon.propTypes = {
  color: PropTypes.string,
  isUnReadNotifications:PropTypes.bool
};

export default NotificationIcon;
