import { Modal, Fade, Box, Typography, Stack, Backdrop, Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

export default function RegistrationPendingModel({ open, title, message, pendingItems, onConfirm, confirmBtnText, handleClose }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          {pendingItems.length &&
            pendingItems.map((pendingItem, index) =>(
              <Typography key={index}>{pendingItem}</Typography>
            ))
          }
          <Box>
            <Stack direction="row" spacing={2} mt={2}>
              <Button variant="contained" type="button" onClick={onConfirm} color="primary">
                {confirmBtnText}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

RegistrationPendingModel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  pendingItems: PropTypes.arrayOf(PropTypes.string),
  onConfirm: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};