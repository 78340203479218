import React, { useEffect, useRef, useState } from "react";
import "./Header.scss";
import logo from "../../Assets/Icons/logo.png";
import menuOpenIcon from "../../Assets/Icons/menu-open.png";
import menuCloseIcon from "../../Assets/Icons/menu-close.png";
import SubHeader from "../SubHeader/SubHeader";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, loginSelector } from "../../store/features/auth/authSlice";
import { Avatar, IconButton, Popover, Tooltip } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import { headerMenus } from "../../../utils/MenusConfig";
import LanguageSwitcher from "../LanguageSwitcher";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HeaderNotifications from "../Notifications";
import { useTranslation } from "react-i18next";
import { roleFilter } from "../../../utils/moduleHelper";
import HeaderVideos from "../Videos";
import { getApi } from "../../../utils/services";
import { GET_EMPLOYEE_COURSES } from "../../../utils/services/apiPath";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useSelector(loginSelector);
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [filteredHeaderMenus, setFilteredHeaderMenus] = useState(user ? headerMenus.filter(roleFilter(user)) : []);
  const translatedMenu = filteredHeaderMenus.map(item => ({ ...item, title: t(item.title) }));

  const ref = useRef(null);
  const dispatch = useDispatch();

  const handleTabClick = tab => {
    navigate(`${tab.to}`);
    const newTheme = localStorage.getItem("theme");
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  const handleMenuOpen = () => setMenuOpen(!menuOpen);

  const onLogout = () => {
    dispatch(handleLogout());
    navigate("/");
  };

  const manageAccount = () => {
    setPopOverOpen(false);
    navigate("/myaccount");
  };

  const handleHome = () => {
    navigate("/dashboard");
  };

  const handleOpenUserManual = () => {
    window.open("../static/Documents/The ProScore User Manual.pdf");
  };

  const getEmployeeCourses = async () => {
    const { error, data } = await getApi(`${GET_EMPLOYEE_COURSES}?employeeGuid=${user.employeeId}`);
    if (error) {
      return errorToast(error);
    }

    const updatedItems = getUpdatedItems(data);
    updateFilteredHeaderMenus(updatedItems);
  };

  const getUpdatedItems = data => {
    const defaultCourses = [
      {
        courseLink: "https://portal.clicksafety.com/",
        courseName: "OSHA 30-Hour Construction"
      }
    ];
  
    const filteredCourses = user.isApprentice
      ? data
      : [...defaultCourses, ...data];
  
    return filteredCourses;
  };
  

  const updateFilteredHeaderMenus = updatedItems => {
    setFilteredHeaderMenus(filteredHeaderMenus => {
      return filteredHeaderMenus.map(menu => {
        return updateMenuIfMyCourses(menu, updatedItems);
      });
    });
  };

  const updateMenuIfMyCourses = (menu, updatedItems) => {
    if (menu.title === "MyCourses") {
      return {
        ...menu,
        subMenus: getSubMenus(updatedItems)
      };
    }
    return menu;
  };

  const getSubMenus = updatedItems => {
    return updatedItems.map(subMenu => {
      return {
        link: subMenu.courseLink,
        title: subMenu.courseName,
        shouldFireClick: true
      };
    });
  };

  useEffect(() => {
    if (user) {
      setFilteredHeaderMenus(headerMenus.filter(user ? roleFilter(user) : []));
      if (user.isApprentice) {
        getEmployeeCourses();
      }
    }
  }, [user]);

  if (!user) return null;
  const menuIcon = menuOpen ? menuCloseIcon : menuOpenIcon;
  return (
    <div className="header">
      <div className="header_main">
        <div className="header_container">
          <div className="header_logo">
            <div className="header_logo_menu-open">
              <button onClick={handleMenuOpen} style={{ border: "none", background: "none" }}>
                <img src={menuIcon} alt="menu-open" />
              </button>
            </div>
            <button onClick={handleHome} style={{ border: "none", background: "none" }}>
              <img src={logo} alt="logo" />
            </button>
          </div>
          <div className="header_user">
            {/* <div className="header_user_sec">
              <HeaderVideos />
            </div>
            <div className="header_user_sec">
              <Tooltip placement="bottom" onClick={handleOpenUserManual} title={t("AccessUserManualTootip")} arrow>
                <IconButton>
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            </div> */}
            <div className="header_user_sec">
              <HeaderNotifications />
            </div>
            <div className="header_user_sec">
              <LanguageSwitcher />
            </div>
            <button className="header_user_sec" ref={ref} onClick={setPopOverOpen} style={{ border: "none", background: "none" }}>
              <IconButton>
                <Avatar className="avatarProfile" alt="user-icon" />
              </IconButton>
            </button>
            <Popover
              disableScrollLock
              anchorEl={ref.current}
              onClose={() => setPopOverOpen(false)}
              open={popOverOpen}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <div className="header_user_popover">
                <div className="header_user_popover_profile">
                  <Avatar src={user.clientUserProfilePicture} alt="user-icon" />
                  <div className="header_user_popover_profile_info">
                    <div className="header_user_popover_profile_info_name">
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="header_user_popover_profile_info_email">{user.email}</div>
                  </div>
                </div>
                <button className="header_user_popover_myAccount" onClick={manageAccount} style={{ border: "none", background: "none" }}>
                  {t("MyAccount")}
                  <ChevronRightTwoToneIcon />
                </button>
                <button className="header_user_popover_logout" onClick={onLogout} style={{ border: "none", background: "none" }}>
                  <LockOpenTwoToneIcon sx={{ mr: 1 }} /> {t("Signout")}
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div className={`header_subheader ${menuOpen && "open"}`}>
        <div className="header_subheader_main">{<SubHeader tabs={translatedMenu} onTabClick={handleTabClick} />}</div>
      </div>
    </div>
  );
};

export default Header;
