import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import InputWrapper from "./InputWrapper";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { Autocomplete, FormHelperText, TextField, Tooltip } from "@mui/material";
import { DEFAULT_SIZE } from "../../../utils/constant";
import HelpIcon from "@mui/icons-material/Help";
import { sortOptions } from "../../../utils/formHelper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SelectFieldComponent = ({ onChange, value, options = [], label, fieldProps, error, withInfoIcon, infoMessage }) => {
  const selectedValue = (options || []).find(option => option.value === value) ?? "";
  return (
    <FormControl className="proInputWithMessage" fullWidth>
      <div className="proInputWithMessage-content">
        <div className={withInfoIcon ? "proInputWithMessage-input" : "proInputWithMessage-input-full"}>
          <Autocomplete
            fullWidth
            options={options}
            value={selectedValue}
            getOptionDisabled={option => option.disabled}
            onChange={(e, value) => onChange(value?.value ?? null)}
            renderInput={params => (
              <TextField {...params} label={label} InputLabelProps={{ shrink: true }} placeholder={label} variant="outlined" fullWidth />
            )}
            {...fieldProps}
          />
        </div>
        {withInfoIcon && (
          <div className="proInputWithMessage-info">
            <Tooltip title={infoMessage} placement="top">
              <HelpIcon color="primary" />
            </Tooltip>
          </div>
        )}
      </div>
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  );
};

SelectFieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  fieldProps: PropTypes.object,
  error: PropTypes.object,
  withInfoIcon: PropTypes.bool,
  infoMessage: PropTypes.string
};

export default function ProSelectField({
  name,
  mode,
  label,
  rules,
  control,
  infoMessage,
  isAddMode,
  options = [],
  fieldProps = {},
  size = DEFAULT_SIZE,
  withInfoIcon = false,
  startAdornment = null,
  ...rest
}) {
  const { t } = useTranslation();
  const sortedOptions = useMemo(() => sortOptions(options), [options]);
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed))
    return <ReadOnlyInput size={size} options={options} type="select" label={t(label)} name={name} {...rest} />;

  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectFieldComponent
            onChange={onChange}
            value={value}
            options={sortedOptions}
            error={error}
            label={t(label)}
            fieldProps={fieldProps}
            withInfoIcon={withInfoIcon}
            infoMessage={infoMessage}
          />
        )}
      />
    </InputWrapper>
  );
}

ProSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  infoMessage: PropTypes.string,
  isAddMode: PropTypes.bool,
  options: PropTypes.array,
  fieldProps: PropTypes.object,
  size: PropTypes.string,
  withInfoIcon: PropTypes.bool,
  startAdornment: PropTypes.node
};
