import { Button } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./index.scss";
import { MenuItems } from "../SubHeader/SubHeader";
import { useWindowSize } from "../../../utils/customHooks/useWindowSize";
import DetailSubHeader from "./DetailSubHeader";
import PropTypes from 'prop-types';

export default function DetailHeader({
  title,
  subTitle,
  backAction,
  backLabel,
  tabs,
  activeTab,
  handleTabClick,
  fromGridAndForm = false,
  editButton = null,
  hideBackbutton = false
}) {
  const { isMobile } = useWindowSize();
  return (
    <div className="proDetail-header-wrapper">
      <div className="proDetail-header">
        <div className="proDetail-header-title">
          <h1 className={fromGridAndForm ? "mNoFull" : ""}>{title}</h1>
          {subTitle && (
            <h2>
              <span>-&nbsp;</span>
              {subTitle}
            </h2>
          )}
          {editButton}
        </div>
        {!hideBackbutton && (
          <div className="proDetail-header-back">
            <Button variant="contained" size="medium" onClick={backAction} startIcon={<ArrowBackIcon />}>
              {isMobile ? "" : backLabel}
            </Button>
          </div>
        )}
      </div>
      {tabs &&
        (isMobile ? (
          <DetailSubHeader value={activeTab} tabs={tabs} onChange={handleTabClick} />
        ) : (
          <div className="proDetail-tabs">
            <MenuItems tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
          </div>
        ))}
    </div>
  );
}

DetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  backAction: PropTypes.func.isRequired,
  backLabel: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ),
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  fromGridAndForm: PropTypes.bool,
  editButton: PropTypes.node,
  hideBackbutton: PropTypes.bool
};