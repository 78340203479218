import { Button } from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./NameCell.scss";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export default function NameCell({ params, onClick, getRedirectUrl, showIcon = false }) {
  const { value, row } = params;
  const navigate = useNavigate();

  const formatDate = (date) => {
    if (typeof date === 'string' && !isNaN(Date.parse(date))) {
      const parsedDate = new Date(date);
      return `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
    }
    return date;
  };
  const formattedValue = formatDate(value);

  const navigateToDetail = () => {
    if (getRedirectUrl) {
      const redirectLink = getRedirectUrl(params);
      return navigate(redirectLink);
    }
    if (row.nameLink) navigate(row.nameLink);
  };

  const handleClick = () => {
    if (onClick) onClick(params);
    else navigateToDetail();
  };

  return (
    <Button className="proGrid-name" variant="text" color="primary" onClick={handleClick} startIcon={showIcon ? <AccountCircleIcon /> : null} >
    {formattedValue}
    </Button>
  );
}

NameCell.propTypes = {
  params: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  getRedirectUrl: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
};