const enJSON = {
  Portal: 'Portal',
  General: 'General',
  Dashboard: 'Dashboard',
  LanguageSwitcher: 'Language Switcher',
  Notifications: 'Notifications',
  MyAccount: 'My Account',
  ThemeSettings: 'Theme Settings',
  GeneralSettings: 'General Settings',
  DarkColorSchemes: 'Dark color schemes',
  LightColorSchemes: 'Light color schemes',
  Signout: 'Sign out',
  Projects: 'Projects',
  Registration: 'Registration',
  SubHeading: 'Fill the form below',
  ApprenticePersona: 'Apprentice Persona',
  CurrentProject: 'Current Project',
  Location: 'Location',
  RAPCategory: 'RAP Category',
  RegisteredApprenticeshipProgram: 'Registered Apprenticeship Program',
  Mentor: 'Mentor',
  Employer: 'Employer',
  WageEarned: 'Wage Earned to Date',
  PendingApproval: 'Pending Approval',
  WPSAdjPending: 'WPS Adj. Pending',
  WPSSubmission: 'WPS Submission',
  OTJHoursLabel: 'OTJ Hours',  
  OTJHours: 'OTJ / WPS Hours Worked',
  RTIHours: 'RTI Hours Completed',
  OTJTooltipText: 'On The Job Hours / Work Process Schedule ',
  RTITooltipText: 'Related Technical Instructions',
  WPSTracker: 'Work Process Schedule Tracker',
  RTITracker: 'Related Technical Instructions Tracker',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Company: 'Company',
  Project: 'Project',
  Journeyman: 'Journeyman',
  OnlineTraining: 'Online Training',
  BasicInformation: 'Basic Information',
  Payout: 'Payout',
  FirstName: 'First Name',
  MiddleName: 'Middle Name',
  LastName: 'Last Name',
  CellPhone: 'Cell Phone',
  Email: 'Email',
  SSN: 'SSN',
  DateofBirth: 'Date of Birth',
  Sex: 'Sex',
  ApprenticeshipNumber: 'Apprenticeship Number',
  ApprenticeInformation: 'Apprentice Information',
  Ethnicity: 'Ethnicity',
  VeteranStatus: 'Veteran Status',
  Race: 'Race',
  EducationalLevel: 'Educational Level',
  Disability: 'Disability',
  EmploymentStatus: 'Employment Status',
  DateApprenticeshipBegins: 'Date Apprenticeship Begins',
  CareerConnection: 'Career Connection',
  ApprenticeshipsProgram: 'Apprenticeships Program',
  LearningExperience:
    'Any Credit for Previous On-the-job Learning Experience?',
  InstructionExperience:
    'RIL or Related Instruction, any Credit for Previous Instruction Experience?',
  AddressInformation: 'Address Information',
  Address: 'Address',
  Address2: 'Address 2',
  State: 'State',
  City: 'City',
  Zip: 'Zip',
  Submit: 'Submit',
  Cancel: 'Cancel',
  WPSTimeAllocation: 'WPS Time Allocation',
  MyCourses: 'My Courses',
  ApprovedHours: 'Approved Hours',
  RequiredHours: 'Required Hours',
  TotalApprovedHours: 'Total Approved Hours : ',
  ApprovedBy: 'Approved By - ',
  WPSPendingApproval: 'Pending OTJ',
  Documents:'Documents',
  FormName:'Form Name',
  CreatedDate:'Created Date',
  ESignedStatus:'E-Signed Status',
  RAPIDStatus:'RAPID Status',
  Actions:'Actions',
  SignDocument:'Sign Document',
  ProfilePicture:'Profile Picture',
  Upload:'Upload',
  CreditHours:'Credit Hours',
  CreditHoursProgress:'Credit Hours Progress',
  IsApproved:'Is Approved',
  WeekEnding: 'Week Ending',
  WPSPendingApprovalTooltip:'Work Process Schedule Pending Approval',
  WPSPendingAdjustmentTooltip:'Work Process Schedule Adjustment Pending',
  AccessUserManualTootip:'Click here to access the user manual',
  ApprenticeRegistrationPendingTitle:'Apprentice Registration Pending',
  ApprenticeRegistrationTitle:'Apprentice Registration',
  ApprenticeRegistrationPendingText:'You have not filled in some required information needed for processing your Apprentice Registration with the Department of Labor. Please complete the following missing fields:',
  ApprenticeRegistrationRAPPendingText: 'We are working to process your apprentice registration. Once processed, you will be able to start your apprentice journey.',
  ApprenticeRegistrationText:'Your Apprentice Registration Form (Form 671) is now available for signature, please click OK to view and sign the Registration Form.',
  EditDetails: 'Edit Details',
  Save: 'Save',
  MyMentors: "My Mentors",
  MentorName: "Mentor Name",
  ApprenticeName: "Apprentice Name",
  ProjectName: "Project Name",
  WorkDate: "Work Date",
  employeeName: "Employee Name",
  estimatedHours: "Estimated Hours",
  Videos: "Videos"
};

export default enJSON;
