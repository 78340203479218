export const logoutHelper = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("expiresIn");
};

export const loginHelper = data => {
  const { user, token, clientUserProfilePicture, clientProfilePicture } = data;
  const { accessToken } = token;
  const { expiresIn } = token;
  localStorage.setItem(
    "user",
    JSON.stringify({ ...user, clientUserProfilePicture: clientUserProfilePicture?.path, clientProfilePicture: clientProfilePicture?.path })
  );
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("expiresIn", expiresIn);
};
