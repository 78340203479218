import React from "react";
import Login from "../Pages/Login/Login";
import Identity from "../Pages/Login/Identity";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import Dashboard from "../Pages/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import RegistrationForm from "../Pages/Registration/RegistrationForm";
import DocumentList from "../Pages/Documents/DocumentList";
import ESignatureList from "../Pages/Documents/ESignatureList";
import MyCourses from "../Pages/MyCourses";
import ProjectList from "../Pages/Projects/Projects";
import ConfirmMentor from "../Pages/ConfirmMentor/ConfirmMentors";
import RequestApprentice from "../Pages/RequestApprentice/RequestApprentice";
import MyAccount from "../Pages/MyAccount";
import MyMentors from "../Pages/MyMentors";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import ValidateOtp from "../Pages/ValidateOtp/ValidateOtp";
import DailyTimeAllocation from "../Pages/Wps/DailyWpsTimeAllocation";

const router = [
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/identity-callback",
    element: <Identity />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/resetpassword-otp",
    element: <ValidateOtp />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute Component={Dashboard} />,
    role: "employee"
  },
  {
    path: "/projects",
    element: <ProtectedRoute Component={ProjectList} />
  },
  {
    path: "/e-signature",
    element: <ProtectedRoute Component={ESignatureList} />,
    role: "employee"
  },
  {
    path: "/documents",
    element: <ProtectedRoute Component={DocumentList} />,
    role: "employee"
  },
  {
    path: "/registration",
    element: <ProtectedRoute Component={RegistrationForm} />,
    role: "employee"
  },
  {
    path: "/wps-time-allocation",
    element: <ProtectedRoute Component={DailyTimeAllocation} />
  },
  {
    path: "/confirm-mentor",
    element: <ProtectedRoute Component={ConfirmMentor} />
  },
  {
    path: "/request-apprentice",
    element: <ProtectedRoute Component={RequestApprentice} />
  },
  {
    path: "/my-courses/:id",
    element: <MyCourses />,
    role: "employee"
  },
  {
    path: "/myaccount",
    element: <ProtectedRoute Component={MyAccount} />,
    role: "employee"
  },
  {
    path: "/my-mentors",
    element: <ProtectedRoute Component={MyMentors} />,
    role: "employee"
  }
];

export default router;
