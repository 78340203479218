import DashboardIcon from "../App/Assets/IconComponents/Dashboard";
import EmployeesIcon from "../App/Assets/IconComponents/Employees";
import ProjectsIcon from "../App/Assets/IconComponents/Projects";
import ReportsIcon from "../App/Assets/IconComponents/Reports";
import RapIcon from "../App/Assets/IconComponents/Rap";

export const headerMenus = [
  {
    to: "/dashboard",
    title: "Dashboard",
    hasArrow: false,
    icon: DashboardIcon,
    role: "employee"
  },
  {
    to: "/projects",
    title: "Projects",
    icon: ProjectsIcon
  },
  {
    to: "/e-signature",
    title: "E Signature",
    hasArrow: false,
    icon: ReportsIcon,
    role: "employee"
  },
  {
    to: "/documents",
    title: "Documents",
    hasArrow: false,
    icon: ReportsIcon,
    role: "employee"
  },
  {
    to: "/registration",
    title: "Registration",
    hasArrow: false,
    icon: EmployeesIcon,
    role: "employee"
  },
  {
    to: "/wps-time-allocation",
    title: "WPSTimeAllocation",
    hasArrow: false,
    icon: RapIcon
  },
  {
    to: "/my-courses",
    title: "MyCourses",
    hasArrow: true,
    icon: EmployeesIcon,
    role: "employee",
    subMenus: [
      {
        link: "/my-courses/osha-30-hour-construction",
        title: "OSHA 30-Hour Construction"
      },
      {
        link: "/my-courses/introduction-to-solar-photovoltaics",
        title: "Introduction to Solar Photovoltaics"
      }
    ]
  },
  {
    to: "/my-mentors",
    title: "MyMentors",
    hasArrow: false,
    icon: EmployeesIcon,
    role: "employee"
  }
];
