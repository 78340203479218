import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "./App/ContextStore/ThemeContext";
import { store } from "./App/store";
import { Provider } from "react-redux";
import { BASE_MUI_KEY } from "./utils/constant";

// Activate Pro liencene for MUI
LicenseInfo.setLicenseKey(BASE_MUI_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
