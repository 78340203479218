import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useColumn = (initialColumns, canDelete = true) => {
  const { i18n, t } = useTranslation();
  const [columns, setColumns] = useState(initialColumns.map(col => ({ ...col, headerName: t(col.headerName) })));

  useEffect(() => {
    i18n.on("languageChanged", () => {
      setColumns([...initialColumns.map(col => ({ ...col, headerName: t(col.headerName) }))]);
    });
    return () => {
      i18n.off("languageChanged");
    };
  }, [initialColumns]);

  return useMemo(() => {
    return columns.filter(col => (col.isDeleteColumn ? canDelete : true)).map(col => ({ ...col, minWidth: col.width || 150 }));
  }, [columns, canDelete]);
};

export default useColumn;
